$theme: ('theme-heavyitalic-font': ('family': 'calluna-sans', 'weight': 700, 'style': 'italic'), 'theme-semibold-font': ('family': 'calluna-sans', 'weight': 600, 'style': 'normal'), 'theme-heavy-font': ('family': 'calluna-sans', 'weight': 700, 'style': 'normal'), 'theme-semibolditalic-font': ('family': 'calluna-sans', 'weight': 600, 'style': 'italic'), 'theme-regular-font': ('family': 'calluna-sans', 'weight': 400, 'style': 'normal'), 'theme-regular-italic-font': ('family': 'calluna-sans', 'weight': 400, 'style': 'italic'), 'theme-logotype-max-width': 154px, 'theme-logotype-minimized-width': 80px, 'theme-logotype-mobile-width': 60px, 'theme-intro-background-color': #1C2024, 'theme-intro-background-image': '../img/albertbonnier-top2.png', 'theme-intro-books-author-color': #FFFFFF, 'theme-intro-bonnier-text-color': #FFFFFF, 'theme-intro-main-text-color': #FFFFFF, 'theme-intro-search-text-color': #FFFFFF, 'theme-intro-search-icon': '../img/icon-magnifier-white-small.svg', 'theme-menu-bg': #FFFFFF, 'theme-menu-bar-bg': #C7361E, 'theme-hamburger-color': #FFFFFF, 'theme-news-background-color': #F4F4F4, 'theme-news-arrow-color': '../img/icon-arrow-right-red.svg', 'theme-news-title-color': #202020, 'theme-news-section-title-color': #202020, 'theme-books-section-title': #202020, 'theme-authors-section-title': #FFFFFF, 'theme-authors-bg-color': #1C2024, 'theme-themes-title-color': #FFFFFF, 'theme-contact-puff-bg': #C7361E, 'theme-contact-puff-color': #FFFFFF, 'theme-qa-color': #202020, 'theme-site-standard-color': #C7361E, 'theme-button-bg-color': #C7361E, 'theme-button-text-color': #FFFFFF, 'theme-article-image-shadow-color': #C7361E, 'theme-login-icon': '../img/icon-login.svg', 'theme-cart-icon': '../img/icon-cart.svg', 'theme-account-icon': '../img/icon-account.svg');body {
      padding-top: 0 !important;
}

html {
      margin-top: 0 !important;
}

.menu-bar {
      position: relative;

      .site-menu__login,
      .site-menu__hamburger {
            display: none !important;
      }
}

.article {
      &__breadcrumbs,
      &__files,
      &__share,
      &__tags{
            display: none;
      }

      &__content {
            border-bottom: 0;
      }
}
.footer {
      display: none;
}