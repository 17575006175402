body {
  padding-top: 0 !important;
}

html {
  margin-top: 0 !important;
}

.menu-bar {
  position: relative;
}
.menu-bar .site-menu__login,
.menu-bar .site-menu__hamburger {
  display: none !important;
}

.article__breadcrumbs, .article__files, .article__share, .article__tags {
  display: none;
}
.article__content {
  border-bottom: 0;
}

.footer {
  display: none;
}